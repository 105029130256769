<template>
  <el-container class="h-full">
    <el-container>
      <el-main>
        <div class="header-container">
          <page-header
            style="padding-left: 0.7rem;"
            :title="__('Scheduled Batch Migrations')"
          >
          </page-header>
          <el-button
            class="refreshBtn"
            @click="fetchBatchMigrations"
            :loading="isLoading"
            >{{ __("Refresh") }}</el-button
          >
        </div>

        <div v-loading="isLoading">
          <!-- Main batches table -->
          <el-table
            ref="batchTable"
            :data="tableBatchMigrations"
            style="width: auto; border-bottom: 1px solid #A0A8B5;"
            border
            :default-sort="{ prop: 'created_at', order: 'descending' }"
            @row-click="toggleExpandRow"
            row-key="id"
            class="migration-table"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <div class="expanded-content">
                  <h4>{{ __("Tasks in This Batch") }}</h4>

                  <div
                    v-if="
                      getTasksForBatch(props.row).length === 0 &&
                        props.row.s6_task_ids &&
                        props.row.s6_task_ids.length > 0
                    "
                  >
                    <p>{{ __("Task IDs") }}:</p>
                    <el-tag
                      v-for="taskId in props.row.s6_task_ids"
                      :key="taskId"
                      size="medium"
                    >
                      {{ taskId }}
                    </el-tag>
                  </div>

                  <el-table
                    v-else
                    :data="getTasksForBatch(props.row)"
                    style="width: auto;"
                    border
                    class="migration-table"
                  >
                    <el-table-column
                      prop="task_id"
                      label="Task ID"
                      width="180"
                    ></el-table-column>
                    <el-table-column
                      prop="job_uuid"
                      label="Migration Job UUID"
                      width="300"
                    ></el-table-column>
                    <el-table-column prop="status" label="Status" width="180">
                      <template slot-scope="scope">
                        <el-tag
                          :type="getStatusType(scope.row.status)"
                          size="medium"
                        >
                          {{ scope.row.status }}
                        </el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="created_at"
                      label="Created At"
                      width="180"
                    >
                      <template slot-scope="scope">
                        {{ formatDateTime(scope.row.created_at) }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="updated_at"
                      label="Updated At"
                      width="180"
                    >
                      <template slot-scope="scope">
                        {{ formatDateTime(scope.row.updated_at) }}
                      </template>
                    </el-table-column>
                  </el-table>

                  <h4>{{ __("Tables to Migrate") }}</h4>
                  <div
                    v-if="
                      !props.row.tables_to_migrate ||
                        props.row.tables_to_migrate.length === 0
                    "
                  >
                    <p class="no-data-text">
                      {{ __("No tables information available") }}
                    </p>
                  </div>
                  <div v-else class="tables-list">
                    <el-tag
                      v-for="table in props.row.tables_to_migrate"
                      :key="table"
                      size="medium"
                    >
                      {{ table }}
                    </el-tag>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="id"
              label="Batch ID"
              width="180"
              sortable
            ></el-table-column>
            <el-table-column
              prop="s6_group_id"
              label="S6 Group ID"
              width="180"
              sortable
            ></el-table-column>
            <el-table-column
              prop="s7_account_id"
              label="S7 Account ID"
              width="180"
              sortable
            ></el-table-column>
            <el-table-column prop="status" label="Status" width="180">
              <template slot-scope="scope">
                <el-tag :type="getStatusType(scope.row.status)" size="medium">
                  {{ scope.row.status }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="paused" label="Paused" width="180">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.paused"
                  :loading="pauseLoading === scope.row.id"
                  @change="value => togglePauseStatus(scope.row, value)"
                  @click.native.stop
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column
              prop="tasks_per_batch"
              label="Tasks Per Batch"
              width="180"
              sortable
            >
              <template slot-scope="scope">
                <el-input
                  v-if="scope.row.status === 'Pending'"
                  v-model.number="scope.row.tasks_per_batch"
                  size="small"
                  :min="1"
                  :max="20"
                  @change="value => updateTasksPerBatch(scope.row, value)"
                  @click.native.stop
                >
                </el-input>
                <span v-else>{{ scope.row.tasks_per_batch }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="created_at"
              label="Created At"
              width="180"
              sortable
            >
              <template slot-scope="scope">
                {{ formatDateTime(scope.row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="updated_at"
              label="Updated At"
              width="180"
              sortable
            >
              <template slot-scope="scope">
                {{ formatDateTime(scope.row.updated_at) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PageHeader from "@/components/PageHeader";
import moment from "moment";

export default {
  name: "ViewScheduledBatchMigrations",
  components: {
    PageHeader
  },
  data() {
    return {
      expandedRows: [],
      pauseLoading: null
    };
  },
  computed: {
    ...mapState("studio6serviceproviders", {
      batchMigrations: state => state.batchMigrations || [],
      isLoading: state => state.batchMigrationsLoading
    }),

    tableBatchMigrations() {
      return Array.isArray(this.batchMigrations) ? this.batchMigrations : [];
    }
  },
  methods: {
    ...mapActions("studio6serviceproviders", [
      "getBatchMigrations",
      "updateBatchMigration"
    ]),

    fetchBatchMigrations() {
      this.getBatchMigrations();
    },

    formatDateTime(dateTime) {
      if (!dateTime) return "-";
      return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    },

    getStatusType(status) {
      switch (status?.toLowerCase()) {
        case "completed":
          return "success";
        case "in progress":
        case "pending":
        case "Pending":
          return "warning";
        case "failed":
          return "danger";
        default:
          return "info";
      }
    },

    getTasksForBatch(batch) {
      if (!batch || !batch.tasks) return [];

      let tasks = batch.tasks;

      tasks = Object.values(tasks).filter(task => task !== null);

      return tasks.map(task => ({
        task_id: task.task_id || task.id || "",
        job_uuid: task.migration_job_uuid,
        status: task.status || "Pending",
        created_at: task.created_at,
        updated_at: task.updated_at
      }));
    },

    toggleExpandRow(row) {
      const $table = this.$refs.batchTable;
      if ($table) {
        $table.toggleRowExpansion(row);
      }
    },

    async togglePauseStatus(batch, value) {
      this.pauseLoading = batch.id;

      try {
        await this.updateBatchMigration({
          id: batch.id,
          data: { paused: value }
        });

        this.$message({
          message: `Batch ${batch.id} ${
            value ? "paused" : "resumed"
          } successfully`,
          type: "success",
          duration: 3000
        });
      } catch (error) {
        batch.paused = !value;

        this.$message({
          message: `Failed to ${
            value ? "pause" : "resume"
          } batch: ${error.message || "Unknown error"}`,
          type: "error",
          duration: 5000
        });

        console.error("Error toggling pause status:", error);
      } finally {
        this.pauseLoading = null;
      }
    },

    async updateTasksPerBatch(row, value) {
      const numValue = parseInt(value);

      // Validate the input
      if (isNaN(numValue) || numValue < 1 || numValue > 20) {
        this.$message({
          type: "error",
          message: __("Tasks per batch must be between 1 and 20")
        });
        // Revert the value
        await this.fetchBatchMigrations();
        return;
      }

      try {
        await this.$confirm(
          __("Are you sure you want to update tasks per batch?"),
          __("Confirm"),
          {
            confirmButtonText: __("Yes"),
            cancelButtonText: __("No"),
            type: "warning"
          }
        );

        await this.updateBatchMigration({
          id: row.id,
          data: { tasks_per_batch: numValue }
        });

        this.$message({
          type: "success",
          message: __("Tasks per batch updated successfully")
        });
      } catch (error) {
        if (error !== "cancel") {
          this.$message({
            type: "error",
            message: error.message || __("Failed to update tasks per batch")
          });
          // Revert the value
          await this.fetchBatchMigrations();
        }
      }
    }
  },

  mounted() {
    this.fetchBatchMigrations();
  }
};
</script>

<style lang="scss" scoped>
.h-full {
  height: 100%;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.refreshBtn {
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 0;
  background-color: var(--theme-color);
  border-color: transparent;
  color: #ffffff;
  &:hover {
    background-color: var(--theme-hover-color);
    border-color: transparent;
    color: #ffffff;
  }
}

.el-main {
  padding: 20px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.migration-table {
  margin: 0;
  width: 100%;

  ::v-deep .el-table {
    width: auto !important;
  }

  ::v-deep .el-table__header th.is-leaf,
  .el-table td {
    border-bottom: 2px solid #f5f5f8;
    text-align: center;
  }

  ::v-deep .el-table__header-wrapper th > .cell {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #a0a8b5;
    text-align: center;
  }

  ::v-deep .el-table__body td > .cell {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep .el-table__expand-icon {
    margin: 0 auto;
  }

  ::v-deep .el-switch {
    margin: 0 auto;
  }

  ::v-deep .el-tag {
    margin: 0 auto;
  }

  ::v-deep .el-table__body tr > td:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ::v-deep .el-table__body tr > td:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ::v-deep .el-table__body tr:hover {
    outline: 2px solid var(--theme-outline-color);
  }

  ::v-deep .el-table__body tr:hover > td {
    background-color: var(--theme-row-hover-color);
  }

  ::v-deep .el-table__body tr.current-row > td {
    background-color: var(--theme-row-hover-color);
  }

  ::v-deep .el-table::before {
    height: 0 !important;
  }

  ::v-deep .el-table__expanded-cell {
    padding: 20px !important;
    background-color: #f8f9fc;
  }

  .expanded-content {
    h4 {
      font-size: 14px;
      font-weight: 600;
      color: #606266;
      margin-bottom: 15px;
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    .el-tag {
      margin-right: 8px;
      margin-bottom: 8px;
    }

    .el-table {
      margin-bottom: 20px;
    }

    .no-data-text {
      color: #909399;
      font-size: 14px;
      margin: 0;
    }

    .tables-list {
      margin-top: 10px;
    }
  }
}

/* Ensure tables don't overflow their container */
.el-table {
  display: table;
  table-layout: fixed;
  width: auto !important;
  max-width: 100%;
  margin-left: 0;
}
</style>
